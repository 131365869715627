<template>
    <v-dialog :value="true" overlay-opacity="0.6" overlay-color="#000" @input="close()" max-width="520px">
        <v-card>
            <v-card-title class="py-2 dcard-title">
                <h3 class="headline mb-0 primary--text text-center">Verify Email Address</h3>
                <v-btn absolute right icon @click.native="close"><v-icon color="primary">fa fa-times-circle</v-icon></v-btn>
            </v-card-title>
            <v-card-text class="pa-0 pb-2 px-0">
                <v-divider />
                <v-row class="mt-2" align-content-center v-if="!$route.query.token || $errors">
                    <v-col cols="12">
                        <v-alert class="lh-12 mx-5 font-weight-medium" dense type="success" v-if="resent">
                            Verification email sent!
                        </v-alert>
                        <v-alert class="mt-2 mx-5" dense type="error" v-if="errors">
                            {{ errors }}
                        </v-alert>
                        <v-row dense class="mx-5 mb-3">
                            <v-col cols="12" md="12">
                                <p class="text-center subtitle-1 lh-12 mb-3">We sent you an email when you signed up<span v-if="user && user.created_at"> ({{ $moment.utc(user.created_at).tz(user.timezone).fromNow() }})</span>.</p>
                                <p class="text-center subtitle-1 lh-12 mb-3">Please click the link included in the email to verify your email address is correct.</p>
                                <p class="text-center subtitle-1 lh-12">Sent to: <span @click="$router.push('/app/account')" class="font-weight-bold hover-item">{{ user.email }}</span></p>
                            <!-- <v-col cols="12" md="8" offset-md="2">
                                <v-text-field
                                    :autofocus="!$vuetify.breakpoint.smAndDown" 
                                    v-model="code"
                                    outlined
                                    v-mask="'######'"
                                    label="Email Verification Code:"
                                    type="tel"
                                    maxlength="6"
                                    class="hide-empty-msgs"
                                    placeholder="Enter code from email. Ex: 234432"
                                    :rules="[rules.required]"
                                    validate-on-blur
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="10" offset-md="1">
                                <v-btn block large rounded :loading="loggingIn" :disabled="code.length<6" @click.native="submit" class="mt-3 mb-0" color="primary">Verify</v-btn>
                            </v-col> -->
                                <v-divider class="mb-4" />
                                <p class="text-center subtitle-1 pb-0 mb-0">
                                    Can't find your verification link?<br>
                                    <v-btn color="primary" class="mt-1" text outlined rounded small :loading="resending" @click.native="resend()">Resend Verification Email</v-btn>
                                </p>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row class="mt-2" align-content-center v-else>
                    <v-col cols="12">
                        <v-row dense class="mx-5 mb-3">
                            <v-col cols="12" md="12">
                                <p class="text-center title lh-12 mt-3 mb-3">Verifying your email address...</p>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions class="card-actions">
                <v-btn text outlined to="/app/account">Change Email Address</v-btn>
                <v-spacer></v-spacer>
                <v-btn text outlined @click.native="close()">Close</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
    
import { mapState, mapMutations } from 'vuex'

export default {
    name: 'AuthEmailVerify',
    computed: {
        ...mapState(['user']),
    },
    data: () => ({
        loggingIn: false,
        code: '',
        email: null,
        errors: null,
        ready: false,
        resent: false,
        resending: false,
        rules: {
            required: value => !!value || 'Required.',
        }
    }),
    methods: {
        ...mapMutations(['setUser']),
        initData(){
            if(this.user && this.user.email){
                this.email = this.user.email;
            }
            if(this.$route.query.token){
                this.code = this.$route.query.token;
                if(!this.email){
                    this.email = (this.$route.query.e) ? this.$route.query.e : this.$route.query.email;
                }
                this.submit();
            }
        },
        submit(){ 
            this.loggingIn = true;
            this.errors = null;
            let request = {
                endpoint: '/auth/verifyemail', 
                method: 'post',
                data: {
                    email: this.email,
                    token: this.code
                },
                apiKey: this.user.api_token || null
            };
            this.$appFn.callApi(request).then((res)=>{
                if(res.status == 200){
                    this.$eventHub.$emit('snackBar', {
                        text: 'Your email has been verified!'
                    });
                    this.setUser(res.data);
                    this.$eventHub.$emit('refreshUser');
                    setTimeout(() => {
                        this.$router.push('/app');
                    }, 1000);
                }else{
                    this.errors = "Invalid code. Please try again.";
                }
            }).catch(()=>{
                this.errors = "Invalid code. Please try again.";
            }).finally(()=>{
                this.loggingIn = false;
            });
        },
        resend(){ 
            this.resending = true;
            this.errors = null;
            let request = {
                endpoint: '/auth/sendverification', 
                method: 'post',
                data: {
                    email: this.user.email,
                },
                apiKey: this.user.api_token || null
            };
            this.$appFn.callApi(request).then((res)=>{
                if(res.status == 204){
                    this.resent = true;
                }else{
                    this.errors = "Please try again.";
                }
            }).catch(()=>{
                this.errors = "Please try again.";
            }).finally(()=>{
                this.resending = false;
            });
        },
        close(){
          this.$emit('closed', true);
        }
    },
    mounted(){
		this.initData();
	},
    watch: {
    }
}
</script>